import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Sharpie Art Workshop",
  "date": "2015-08-02T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.72727272727273%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAAEAgP/2gAMAwEAAhADEAAAAXKEg3YMw9f/xAAYEAADAQEAAAAAAAAAAAAAAAAAAREhAv/aAAgBAQABBQJsXdQ9JlP/xAAWEQEBAQAAAAAAAAAAAAAAAAARARD/2gAIAQMBAT8Bq5//xAAZEQADAAMAAAAAAAAAAAAAAAAAAhIBITH/2gAIAQIBAT8BXCRvpJ//xAAaEAACAgMAAAAAAAAAAAAAAAAAIQEQMVFh/9oACAEBAAY/ApeCU64ar//EAB0QAAIBBAMAAAAAAAAAAAAAAAERIQAQMUFRYYH/2gAIAQEAAT8h6gA4zRCBogm3oiSoFDI74s//2gAMAwEAAgADAAAAEGDf/8QAFhEBAQEAAAAAAAAAAAAAAAAAMQEQ/9oACAEDAQE/EIIz/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EAoBNX//xAAcEAEBAAMAAwEAAAAAAAAAAAABEQAhMUFRYYH/2gAIAQEAAT8QQU8xeyeGnHWTPEigbx1yZpxr6Iq7X3cKgothF/EnzJz/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "sharpie art 01",
          "title": "sharpie art 01",
          "src": "/static/28de4b2812f1faacd919ef76b3b237d8/ddced/sharpie-art-01.jpg",
          "srcSet": ["/static/28de4b2812f1faacd919ef76b3b237d8/35f54/sharpie-art-01.jpg 275w", "/static/28de4b2812f1faacd919ef76b3b237d8/d7854/sharpie-art-01.jpg 550w", "/static/28de4b2812f1faacd919ef76b3b237d8/ddced/sharpie-art-01.jpg 1100w", "/static/28de4b2812f1faacd919ef76b3b237d8/670dc/sharpie-art-01.jpg 1650w", "/static/28de4b2812f1faacd919ef76b3b237d8/0f98f/sharpie-art-01.jpg 1920w"],
          "sizes": "(max-width: 1100px) 100vw, 1100px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Recently got this book by Timothy Goodman that I got to know with his `}<a parentName="p" {...{
        "href": "http://fortydaysofdating.com"
      }}>{`40 Days of Dating`}</a>{` project. If you’re like me and you’re a huge fan of lettering and sketchnoting, this book is for you. We tend to take Sharpies for granted, but they’re an amazing tool that lets you express your creativity in many different ways. I love everything about this book. It’s the perfect size and makes sure to inspire you with every page.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.9090909090909%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABYBAQEBAAAAAAAAAAAAAAAAAAQCA//aAAwDAQACEAMQAAABoahl5yGCT//EABkQAQEBAQEBAAAAAAAAAAAAAAIBAwAEE//aAAgBAQABBQIYmj4jtZCxvDJ6D2iiX//EABkRAAEFAAAAAAAAAAAAAAAAAAACESEiMf/aAAgBAwEBPwGjCtg//8QAGhEAAQUBAAAAAAAAAAAAAAAAAAECERMhMf/aAAgBAgEBPwFbJG80/8QAGRAAAwADAAAAAAAAAAAAAAAAAAERAjEy/9oACAEBAAY/AkzRMRKM5dKj/8QAHBAAAwACAwEAAAAAAAAAAAAAAAERIUExYXGB/9oACAEBAAE/IXcWXNilW89ZrhDi8LJTfgJNaXZ//9oADAMBAAIAAwAAABCsP//EABcRAQEBAQAAAAAAAAAAAAAAABEAAUH/2gAIAQMBAT8QzSSC4v/EABcRAQEBAQAAAAAAAAAAAAAAABEBAEH/2gAIAQIBAT8Qi6EzPW//xAAbEAEBAAMBAQEAAAAAAAAAAAABEQAhMVFBYf/aAAgBAQABPxBlCadvv4YPj25S2c8xyLO1bvj3LEEBgbnmAqK6oLj16OdW5//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "sharpie art 02",
          "title": "sharpie art 02",
          "src": "/static/b55a40c985702b6c7586a2c64faec5d0/ddced/sharpie-art-02.jpg",
          "srcSet": ["/static/b55a40c985702b6c7586a2c64faec5d0/35f54/sharpie-art-02.jpg 275w", "/static/b55a40c985702b6c7586a2c64faec5d0/d7854/sharpie-art-02.jpg 550w", "/static/b55a40c985702b6c7586a2c64faec5d0/ddced/sharpie-art-02.jpg 1100w", "/static/b55a40c985702b6c7586a2c64faec5d0/033f5/sharpie-art-02.jpg 1195w"],
          "sizes": "(max-width: 1100px) 100vw, 1100px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "http://www.amazon.com/Sharpie-Art-Workshop-Techniques-Transforming/dp/1631590480"
      }}>{`You can buy it now on Amazon`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      